.App {
  background: url('./assets/bg.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  direction: rtl;
  font-family: 'Varela Round';
}

@font-face {
  font-family: 'Varela Round';
  src: url('./assets/fonts/VarelaRound-Regular.eot');
  src: url('./assets/fonts/VarelaRound-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/VarelaRound-Regular.woff') format('woff'),
      url('./assets/fonts/VarelaRound-Regular.ttf') format('truetype'),
      url('./assets/fonts/VarelaRound-Regular.svg#VarelaRound-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #eee7;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.row {
  display: flex;
  direction: rtl;
  flex-direction: row;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.glass {
  background-image: linear-gradient(135deg, rgba(66, 66, 66, 0.3), rgba(33, 33, 33, 0.6), rgba(55, 55, 55, 0.2));
  backdrop-filter: blur(6px);
  border-radius: 20px;
  padding: 16px;
  margin: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadowedCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadowedImage {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

label {
  font-size: 32px;
  color: #fffb;
  margin-bottom: 8px;
  text-shadow: 2px 2px #000;
  text-align: center;
}

input, textarea, button, select {
  border: transparent;
  border-radius: 20px;
  min-height: 40px;
  background-color: #0007;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 4px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  color: #fffd;
  font-family: 'Varela Round';
  font-size: 18px;
  line-height: 18px;
  flex: 1;
  width: 0;
  box-sizing: border-box;
}

.round {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  flex: none;
  float: left;
  margin: 8px;
  background-color: #0007;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

textarea {
  display: inline-block !important;
  vertical-align: middle;
  padding: 8px;
  resize: none;
}